@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  font-size: 2em;
  -webkit-margin-before: 1.1em;
          margin-block-start: 1.1em;
}
body {
  background: #F9FAFB;
}

.App {
  text-align: center;
}

.App-header {
  background-color: #60b2e8;
  background-image: linear-gradient(135deg, #60b2e8 0%, #9599E2 100%);
  padding: 6rem 0 8rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  color: white;
}

.App-header h1 {
  margin: 0;
  font-family: 'Nunito Sans', sans-serif;
}

.App-header p {
  margin-top: .5rem;
  font-size: 1.25rem;
}

.App-link {
  color: #61dafb;
}

.App-content {
  max-width: 60rem;
  margin: auto;
  text-align: left;
}
form {
  display: flex;
  justify-content: center;
  align-items: center;
}

input {
  flex-grow: 6;
  border: 1px solid #D8DBE2;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 1rem 1.5rem;
  font-size: 1rem;
}

button {
  width: 9rem;
  background: #4E5DE4;
  color: white;
  font-weight: 500;
  font-family: 'Nunito Sans', sans-serif;
  text-transform: uppercase;
  border: solid 1px #4E5DE4;
  border-radius: .25rem;
  padding: 1rem 1.5rem;
  margin-left: .5rem;
}

button:hover {
  background: #3442c4;
  cursor: pointer;
}

.Container {
  background: white;
  border-radius: 1rem;
  padding: 3rem;
  box-shadow: 0px 8px 16px rgba(44, 55, 75, 0.16);
  z-index: 2;
  margin: 0 2rem;
  margin-top: -4rem;
}

h4 {
  margin: 0;
  text-align: center;
}
ul {
  list-style-type: none;
  -webkit-padding-start: 0;
          padding-inline-start: 0;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  margin: 1rem auto 3rem;
}

li {
  color: #2C374B;
}

.address {
  font-family: 'Courier New', Courier, monospace;
  text-align: left;
  margin-right: 1vmin;
  color: #575C66;
}

.Registrations h3 {
  text-align: center;
  margin: 3rem 0 0;
  color: #575C66;
}

.Registrations {
  padding: 0 2rem;
}
